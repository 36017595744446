import type { MetaField } from '$lib/utils/index';
import { mapToNode } from '$lib/utils/product';

export type CollectionTree = {
	items?: CollectionTree[];
	link: string;
	handle: string;
	legacy_handle: string;
	url_key: string;
	title: string;
};

export type CollectionPathEntry = {
	title: string;
	url_key: string;
	handle: string;
};

export const getCollectionPath = (
	collectionData:
		| {
				edges: { node: { path: MetaField } }[];
		  }
		| undefined
): CollectionPathEntry[] => {
	return mapToNode(collectionData)
		.map((node) => JSON.parse(node.path?.value ?? '[]'))
		.reduce((acc, val) => (val.length > acc.length ? val : acc), []);
};

const _desiredOrder = ['300d1734', '825406ab', '1bec92b0', '2035d535', 'c11b3390'];
export const parseCollectionTree = (categoryTreeJson: string | undefined | null) => {
	const rootElement = categoryTreeJson
		? JSON.parse(categoryTreeJson)
		: { items: [], title: 'Produkte' };

	return _fixLinks({
		...rootElement,
		items: rootElement.items.sort(
			(a1: CollectionTree, a2: CollectionTree) =>
				_desiredOrder.indexOf(a1.handle) - _desiredOrder.indexOf(a2.handle)
		)
	} as CollectionTree);
};

const _fixLinks = (tree: CollectionTree) => {
	return {
		...tree,
		url_key: tree.url_key ? tree.url_key : tree.link,
		items: tree.items?.map(_fixLinks)
	};
};

export function getDepth(tree: CollectionTree, link: string): number {
	if (tree.handle === link || tree.link === link) {
		return 0;
	}
	if (!tree.items) {
		return -1;
	}
	const possibleResults = tree.items.map((nestedTree) => {
		const nesting = getDepth(nestedTree, link);
		if (nesting === -1) {
			return nesting;
		}
		return nesting + 1;
	});
	const result = possibleResults.find((s) => s > 0);
	if (!result) {
		return -1;
	}
	return result;
}

export const findInTreeByName = (
	tree: CollectionTree,
	name: string
): CollectionTree | undefined => {
	if (tree.title === name) {
		return tree;
	}
	if (!tree.items) {
		return undefined;
	}
	const possibleResults = tree.items.map((nestedTree) => findInTreeByName(nestedTree, name));
	const result = possibleResults.find((s) => !!s);
	if (!result) {
		return undefined;
	}
	return result;
};

export const findInTreeLegacy = (
	tree: CollectionTree,
	link: string
): CollectionTree | undefined => {
	const queue = [tree];
	while (queue.length > 0) {
		const current = queue.shift();
		if (current?.legacy_handle === link) {
			return current;
		}
		if (current?.items) {
			queue.push(...current.items);
		}
	}
};

export const findInTree = (tree: CollectionTree, link: string): CollectionTree | undefined => {
	if (tree.handle === link || tree.link === link) {
		return tree;
	}
	if (!tree.items) {
		return undefined;
	}
	const possibleResults = tree.items.map((nestedTree) => findInTree(nestedTree, link));
	const result = possibleResults.find((s) => !!s);
	if (!result) {
		return undefined;
	}
	return result;
};

export const collectTreePath = (tree: CollectionTree, handle: string): CollectionTree[] => {
	if (tree.link === handle || tree.handle === handle) {
		return [tree];
	}

	if (!tree.items) {
		return [];
	}

	const possibleResults = tree.items.map((nestedTree) => collectTreePath(nestedTree, handle));
	const result = possibleResults.find((s) => s.length > 0);
	if (!result) {
		return [];
	}

	return [tree, ...result];
};

export const categoryHandleToUrl = (
	locale: string,
	country: string,
	collectionTree: CollectionTree,
	handle: string | undefined
) => {
	if (!handle) return '#';
	const pathPrefix = collectTreePath(collectionTree, handle)
		.map((entry) => entry.url_key)
		.join('/');
	return `/${locale}-${country}${pathPrefix}/${handle}`;
};

/**
 * Find the nearest ancestor of the current tree that has the given link as a child
 *
 * @param curr The current tree
 * @param top The top level tree
 */
export const oneLevelUp = (
	curr: CollectionTree,
	top: CollectionTree
): CollectionTree | undefined => {
	const queue = [top];
	while (queue.length > 0) {
		const current = queue.shift();
		if (current?.items) {
			if (current.items.some((item) => item.url_key === curr.url_key)) {
				return current;
			}
			queue.push(...current.items);
		}
	}
};
